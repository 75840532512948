/*
Template Name: Appvilla - Creative Landing Page HTML Template.
Author: GrayGrids
*/

(function () {
    //===== Prealoder

    // window.onload = function () {
    //     window.setTimeout(fadeout, 500);
    //     window.setTimeout(setVideoHeight, 500);
    // }
    
    document.addEventListener("turbolinks:load", () => {
        window.setTimeout(fadeout, 500);
        window.setTimeout(setVideoHeight, 500);
    })


    function fadeout() {
        document.querySelector('.preloader').style.opacity = '0';
        document.querySelector('.preloader').style.display = 'none';
    }

    function setVideoHeight() {
        let width = document.getElementById("my-video").offsetWidth;
        let height = width / 16 * 9;
        document.getElementById("my-video").setAttribute("style", "height: " + height + "px");
    }


    /*=====================================
    Sticky
    ======================================= */
    window.onscroll = function () {
        var header_navbar = document.querySelector(".navbar-area");
        var sticky = header_navbar.offsetTop;

        var logo = document.querySelector('.navbar-brand img')
        if (window.pageYOffset > sticky) {
          header_navbar.classList.add("sticky");
          logo.src = document.getElementById('logo-path').innerText;
        } else {
          header_navbar.classList.remove("sticky");
          logo.src = document.getElementById('white-logo-path').innerText;
        }

        // show or hide the back-top-top button
        var backToTo = document.querySelector(".scroll-top");
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            backToTo.style.display = "flex";
        } else {
            backToTo.style.display = "none";
        }
    };


    let scrollTopBtn = document.getElementsByClassName("scroll-top");
    for (var i = 0; i < scrollTopBtn.length; i++) {
        scrollTopBtn[i].addEventListener('click', function () {
            window.scrollTo(0, 0);
        })
    }
    
    // section menu active
	function onScroll(event) {
		var sections = document.querySelectorAll('.page-scroll');
		var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

		for (var i = 0; i < sections.length; i++) {
			var currLink = sections[i];
			var val = currLink.getAttribute('href');
			var refElement = document.querySelector(val);
			var scrollTopMinus = scrollPos + 73;
			if (refElement.offsetTop <= scrollTopMinus && (refElement.offsetTop + refElement.offsetHeight > scrollTopMinus)) {
				document.querySelector('.page-scroll').classList.remove('active');
				currLink.classList.add('active');
			} else {
				currLink.classList.remove('active');
			}
		}
	};

    window.document.addEventListener('scroll', onScroll);
    
    // for menu scroll 
    var pageLink = document.querySelectorAll('.page-scroll');

    pageLink.forEach(elem => {
        elem.addEventListener('click', e => {
            e.preventDefault();
            document.querySelector(elem.getAttribute('href')).scrollIntoView({
                behavior: 'smooth',
                offsetTop: 1 - 60,
            });
        });
    });

    // WOW active
    new WOW().init();

    let filterButtons = document.querySelectorAll('.portfolio-btn-wrapper button');
    filterButtons.forEach(e =>
        e.addEventListener('click', () => {

            let filterValue = event.target.getAttribute('data-filter');
            iso.arrange({
                filter: filterValue
            });
        })
    );

    var elements = document.getElementsByClassName("portfolio-btn");
    for (var i = 0; i < elements.length; i++) {
        elements[i].onclick = function () {
            var el = elements[0];
            while (el) {
                if (el.tagName === "BUTTON") {
                    el.classList.remove("active");
                }
                el = el.nextSibling;
            }
            this.classList.add("active");
        };
    };

    //===== mobile-menu-btn
    let navbarToggler = document.querySelector(".mobile-menu-btn");
    navbarToggler.addEventListener('click', function () {
        navbarToggler.classList.toggle("active");
    });

    //===== Setup button in HERO section based on operating system
    let appDownloadButton = document.getElementById("app-download-btn");
    let os = getMobileOperatingSystem();
    if (os == "iOS") {
        console.log("iOS")
        appDownloadButton.innerHTML = "<a href=\"https://apps.apple.com/us/app/filter-flow/id1619391432\" target=\"_blank\" class=\"btn\"><i class=\"lni lni-apple\"></i> App Store</a>";
    } else if (os == "Android") {
        console.log("Android")
        appDownloadButton.innerHTML = "<a href=\"https://play.google.com/store/apps/details?id=com.laurimnelson.filterandflow\" target=\"_blank\" class=\"btn\"><i class=\"lni lni-play-store\"></i> Google Play</a>"
    } else {
        appDownloadButton.innerHTML = "<a href=\"https://apps.apple.com/us/app/filter-flow/id1619391432\" target=\"_blank\" class=\"btn\"><i class=\"lni lni-apple\"></i> App Store</a><a href=\"https://play.google.com/store/apps/details?id=com.laurimnelson.filterandflow\" target=\"_blank\" class=\"btn btn-alt\"><i class=\"lni lni-play-store\"></i> Google Play</a>";
    }

    let downloadApplicationBtn = document.getElementsByClassName("application-download");
    for (var i = 0; i < downloadApplicationBtn.length; i++) {
        downloadApplicationBtn[i].addEventListener("click", function() {
            let os = getMobileOperatingSystem();
            if (os == 'iOS') {
                window.open("https://apps.apple.com/us/app/filter-flow/id1619391432", "_blank");
            } else {
                window.open("https://play.google.com/store/apps/details?id=com.laurimnelson.filterandflow", "_blank");
            }
        })
    }

    //===== Audio and Video Player Actions
    let letitflowAudioPlayer = document.getElementById("letitflow-audio");
    let letitflowButton = document.getElementById("letitflow-button");

    let hittyourmaxAudioPlayer = document.getElementById("hittyourmax-audio");
    let hittyourmaxButton = document.getElementById("hittyourmax-button");

    let videoPlayer = document.getElementById("my-video");
    let videoPlayer2 = document.getElementById("my-video_html5_api");

    const pauseLetItFlowAudio = () => {
        letitflowAudioPlayer.pause();
        letitflowButton.innerHTML = "<i class=\"lni lni-play\"></i> Play Demo";
    }

    const playLetItFlowAudio = () => {
        letitflowAudioPlayer.play();
        letitflowButton.innerHTML = "<i class=\"lni lni-pause\"></i> Pause Demo";
    }

    const pauseHittYourMaxAudio = () => {
        hittyourmaxAudioPlayer.pause();
        hittyourmaxButton.innerHTML = "<i class=\"lni lni-play\"></i> Play Demo";
    }

    const playHittYourMaxAudio = () => {
        hittyourmaxAudioPlayer.play();
        hittyourmaxButton.innerHTML = "<i class=\"lni lni-pause\"></i> Pause Demo";
    }

    const pauseVideoPlayer = () => {
        try { videoPlayer.pause(); } catch (err) { }
        try { videoPlayer2.pause(); } catch (err) { }
    }

    letitflowButton.addEventListener('click', function() {
        if (!letitflowAudioPlayer.paused) {
            pauseLetItFlowAudio();
        } else {
            pauseVideoPlayer();
            pauseHittYourMaxAudio();
            playLetItFlowAudio();
        }
    });
    letitflowAudioPlayer.addEventListener('ended', function() {
        letitflowButton.innerHTML = "<i class=\"lni lni-play\"></i> Play Demo";
    });

    //==== HIIT Your Max audio playing
    hittyourmaxButton.addEventListener('click', function() {
        if (!hittyourmaxAudioPlayer.paused) {
            pauseHittYourMaxAudio();
        } else {
            pauseVideoPlayer();
            pauseLetItFlowAudio();
            playHittYourMaxAudio();
        }
    });
    hittyourmaxAudioPlayer.addEventListener('ended', function() {
        hittyourmaxButton.innerHTML = "<i class=\"lni lni-play\"></i> Play Demo";
    });

    videoPlayer.addEventListener('play', function() {
        pauseHittYourMaxAudio();
        pauseLetItFlowAudio();
    })

    document.getElementById("year").innerHTML = new Date().getFullYear();
})();

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

function downloadApplication() {
    let os = getMobileOperatingSystem();
    if (os == 'iOS') {
        window.open("https://apps.apple.com/us/app/filter-flow/id1619391432", "_blank");
    } else {
        window.open("https://play.google.com/store/apps/details?id=com.laurimnelson.filterandflow", "_blank");
    }
}